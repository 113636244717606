import React, { useState } from "react";
import { Image, Radio, Space, Table, Tabs } from "antd";
import { dataTabMB, dataTabMN } from "../dataTable/tab";

const Home = () => {
  const [tabPosition, setTabPosition] = useState("left");
  const [tabBacNam, setTabBacNam] = useState(true);
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  const changeTabBacNam = (e) => {
    setTabBacNam(e.target.value);
  };
  return (
    <>
      <Image
        width={250}
        src="https://hupuna.storeakasa.com/wp-content/uploads/2024/03/logo.svg"
        style={{ padding: 15 }}
      />
      <Space style={{marginRight: 5}}>
        <Radio.Group value={tabBacNam} onChange={changeTabBacNam}>
          <Radio.Button value={true}>Miền Bắc</Radio.Button>
          <Radio.Button value={false}>Miền Nam</Radio.Button>
        </Radio.Group>
      </Space>
      <Space style={{}}>
        <Radio.Group value={tabPosition} onChange={changeTabPosition}>
          <Radio.Button value="top">Trên</Radio.Button>
          <Radio.Button value="bottom">Dưới</Radio.Button>
          <Radio.Button value="left">Trái</Radio.Button>
          <Radio.Button value="right">Phải</Radio.Button>
        </Radio.Group>
      </Space>
      {tabBacNam ? (
        <Tabs tabPosition={tabPosition} items={dataTabMB} />
      ) : (
        <Tabs tabPosition={tabPosition} items={dataTabMN} />
      )}
    </>
  );
};
export default Home;
