import React, { useState } from "react";
import { Tabs } from "antd";
import { CPNTable } from "../reComponent/table";
import {
  HN_Nap_Doi_5LBC_1nau,
  HN_Nap_Doi_Nau_Moc_3LB,
  HN_Nap_Doi_Vien_Ke_BE_TONG_1N1M_3LC,
  HN_Nap_Gai_Nau_Moc_3LE,
  HN_Nap_Gai_Trang_Nau_3LE,
} from "../dataTable/hopcarton";

const HopCarton = () => {
  const [tabPosition, setTabPosition] = useState("top");
  const columnsHopCarton = [
    {
      title: "Kích thước",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Giá bán",
      dataIndex: "price",
      key: "price",
    },
  ];
  const dataTab = [
    {
      key: `1`,
      label: `Nắp Đối 3LB Nâu Mộc`,
      children: CPNTable(columnsHopCarton,HN_Nap_Doi_Nau_Moc_3LB),
    },
    {
      key: `2`,
      label: `Nắp Đối 5LBC 1 Nâu`,
      children: CPNTable(columnsHopCarton,HN_Nap_Doi_5LBC_1nau),
    },
    {
      key: `4`,
      label: `Nắp Gài 3LE Nâu Mộc`,
      children: CPNTable(columnsHopCarton,HN_Nap_Gai_Nau_Moc_3LE),
    },
    {
      key: `5`,
      label: `Nắp Gài 3LE Trắng Nâu`,
      children: CPNTable(columnsHopCarton,HN_Nap_Gai_Trang_Nau_3LE),
    },
    {
      key: `3`,
      label: `Nắp Đối Viên Kê Bê tông`,
      children: CPNTable(columnsHopCarton,HN_Nap_Doi_Vien_Ke_BE_TONG_1N1M_3LC),
    },
  ];
  return (
    <>
      <Tabs tabPosition={tabPosition} items={dataTab} />
    </>
  );
};
export default HopCarton;
