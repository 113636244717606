import HopCartonMB from "../hopcarton/index";
import HopCartonMN from "../hopcarton/indexMN";
import TuiGiay from "../tuigiay";


const dataTabMB = [
  { key: `1`, label: `Hộp Carton`, children: <HopCartonMB /> },
  { key: `2`, label: `Túi giấy`, children: <TuiGiay /> },
  { key: `3`, label: `Màng PE`, children: `đây là tab 3` },
  { key: `4`, label: `Băng Dính`, children: `đây là tab 4` },
  { key: `5`, label: `Xốp Nổ, Bóng Khí`, children: `đây là tab 4` },
  { key: `6`, label: `Xốp Foam`, children: `đây là tab 4` },
  { key: `7`, label: `Dụng Cụ Cắt Băng Dính`, children: `đây là tab 4` },
];

const dataTabMN = [
  { key: `1`, label: `Hộp Carton`, children: <HopCartonMN /> },
  { key: `2`, label: `Túi giấy`, children: `đây là tab 2` },
  { key: `3`, label: `Màng PE`, children: `đây là tab 3` },
  { key: `4`, label: `Băng Dính`, children: `đây là tab 4` },
  { key: `5`, label: `Xốp Nổ, Bóng Khí`, children: `đây là tab 4` },
  { key: `6`, label: `Xốp Foam`, children: `đây là tab 4` },
  { key: `7`, label: `Dụng Cụ Cắt Băng Dính`, children: `đây là tab 4` },
];

export { dataTabMB, dataTabMN };
