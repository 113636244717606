import React, { useState } from "react";
import { Tabs } from "antd";
import { CPNTable } from "../reComponent/table";
import {
  SG_Nap_Doi_Nau_Moc_3LB,
  SG_Nap_Doi_Nau_Moc_5LB,
  SG_Nap_Gai_Nau_Moc_3LE,
  SG_Nap_Gai_Trang_Nau_3LE,
} from "../dataTable/hopcarton";

const HopCartonMN = () => {
  const [tabPosition, setTabPosition] = useState("top");
  const columnsHopCarton = [
    {
      title: "Kích thước",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Giá bán",
      dataIndex: "price",
      key: "price",
    },
  ];
  const dataTab = [
    {
      key: `1`,
      label: `Nắp Đối 3LB Nâu Mộc`,
      children: CPNTable(columnsHopCarton,SG_Nap_Doi_Nau_Moc_3LB),
    },
    {
      key: `2`,
      label: `Nắp Đối 5LBC Nâu Mộc`,
      children: CPNTable(columnsHopCarton,SG_Nap_Doi_Nau_Moc_5LB),
    },
    {
      key: `4`,
      label: `Nắp Gài 3LE Nâu Mộc`,
      children: CPNTable(columnsHopCarton,SG_Nap_Gai_Nau_Moc_3LE),
    },
    {
      key: `5`,
      label: `Nắp Gài 3LE Trắng Nâu`,
      children: CPNTable(columnsHopCarton,SG_Nap_Gai_Trang_Nau_3LE),
    },
  ];
  return (
    <>
      <Tabs tabPosition={tabPosition} items={dataTab} />
    </>
  );
};
export default HopCartonMN;
