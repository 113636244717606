const HN_Nap_Doi_Nau_Moc_3LB = [
  {
    size: "10x10x10(size 5)",
    price: 1150,
  },
  {
    size: "10x10x5 (size 3)",
    price: 930,
  },
  {
    size: "10x10x8(size 4)",
    price: 945,
  },
  {
    size: "10x6x6 (size 1)",
    price: 699,
  },
  {
    size: "10x7x7 (size 2)",
    price: 899,
  },
  {
    size: "12x10x5 (size 8)",
    price: 1150,
  },
  {
    size: "12x12x12 (size 9)",
    price: 1550,
  },
  {
    size: "12x8x12 (size 7)",
    price: 1200,
  },
  {
    size: "12x8x8 (size 6)",
    price: 999,
  },
  {
    size: "15x10x10 (size 12)",
    price: 1290,
  },
  {
    size: "15x10x20 (size 13)",
    price: 1800,
  },
  {
    size: "15x10x5 (size 11)",
    price: 1099,
  },
  {
    size: "15x12x10 (size 14)",
    price: 1350,
  },
  {
    size: "15x12x12",
    price: 1680,
  },
  {
    size: "15x15x10",
    price: 1890,
  },
  {
    size: "15x15x15",
    price: 2150,
  },
  {
    size: "15x8x6 (size 10)",
    price: 999,
  },
  {
    size: "16x12x6 (size 16)",
    price: 1245,
  },
  {
    size: "16x16x6",
    price: 2050,
  },
  {
    size: "16x6x6 (size 15)",
    price: 990,
  },
  {
    size: "18x10x8 (size 17)",
    price: 1250,
  },
  {
    size: "18x12x12 (size 18)",
    price: 1700,
  },
  {
    size: "18x12x8",
    price: 1600,
  },
  {
    size: "18x14x12",
    price: 1990,
  },
  {
    size: "20x10x10 (size 21)",
    price: 1590,
  },
  {
    size: "20x10x6 (size 20)",
    price: 1550,
  },
  {
    size: "20x12x10",
    price: 1980,
  },
  {
    size: "20x12x8(size 22)",
    price: 1590,
  },
  {
    size: "20x15x10 (size 23)",
    price: 1990,
  },
  {
    size: "20x15x15 (size 24)",
    price: 2350,
  },
  {
    size: "20x16x6 (size 25)",
    price: 2260,
  },
  {
    size: "20x18x12",
    price: 2600,
  },
  {
    size: "20x20x10 (size 26)",
    price: 2580,
  },
  {
    size: "20x20x15 (size 27)",
    price: 2950,
  },
  {
    size: "20x20x20",
    price: 3690,
  },
  {
    size: "20x8x8 (size 19)",
    price: 1250,
  },
  {
    size: "22x19x14",
    price: 3300,
  },
  {
    size: "22x19x7",
    price: 2600,
  },
  {
    size: "25x10x10 ( size 28)",
    price: 1690,
  },
  {
    size: "25x10x5  (size 51)",
    price: 1340,
  },
  {
    size: "25x13x18",
    price: 4296,
  },
  {
    size: "25x15x10 (size 29)",
    price: 2240,
  },
  {
    size: "25x15x15 (size 30)",
    price: 2650,
  },
  {
    size: "25x17x7",
    price: 2600,
  },
  {
    size: "25x20x10 (size 31)",
    price: 2890,
  },
  {
    size: "25x20x15 (size 32)",
    price: 3790,
  },
  {
    size: "25x22x12 (size 55)",
    price: 3550,
  },
  {
    size: "25x22x15",
    price: 3900,
  },
  {
    size: "25x22x20 (size 33)",
    price: 4150,
  },
  {
    size: "25x25x10",
    price: 4120,
  },
  {
    size: "25x25x15",
    price: 4360,
  },
  {
    size: "25x25x25",
    price: 5290,
  },
  {
    size: "28x11x8",
    price: 2150,
  },
  {
    size: "28x17x7",
    price: 2750,
  },
  {
    size: "28x18x12",
    price: 3150,
  },
  {
    size: "28x20x8",
    price: 3100,
  },
  {
    size: "28x23x16 (size 34)",
    price: 4390,
  },
  {
    size: "28x24x11 (size 35)",
    price: 4300,
  },
  {
    size: "30x10x10 (size 36)",
    price: 1900,
  },
  {
    size: "30x15x10 (size 37)",
    price: 2560,
  },
  {
    size: "30x15x15 (size 38)",
    price: 3160,
  },
  {
    size: "30x20x10 (size 39)",
    price: 3150,
  },
  {
    size: "30x20x15 (size 40)",
    price: 4450,
  },
  {
    size: "30x20x20",
    price: 4590,
  },
  {
    size: "30x21x7",
    price: 3300,
  },
  {
    size: "30x25x20 (size 41)",
    price: 5050,
  },
  {
    size: "30x25x25 (size 42)",
    price: 6550,
  },
  {
    size: "30x30x10",
    price: 5050,
  },
  {
    size: "30x30x20",
    price: 6900,
  },
  {
    size: "30x30x30",
    price: 7800,
  },
  {
    size: "33x22x12 (size 43)",
    price: 4210,
  },
  {
    size: "34x24x18 (size 44)",
    price: 5390,
  },
  {
    size: "35x25x15",
    price: 5545,
  },
  {
    size: "35x25x20 (size 56)",
    price: 5950,
  },
  {
    size: "40x20x20",
    price: 5950,
  },
  {
    size: "40x30x20 (size 49)",
    price: 8650,
  },
  {
    size: "40x30x30 (size 50)",
    price: 11500,
  },
  {
    size: "40x40x30",
    price: 12230,
  },
  {
    size: "40x40x40",
    price: 13600,
  },
  {
    size: "43x31x23cm (Thùng A3)",
    price: 8300,
  },
  {
    size: "43x35x30",
    price: 12500,
  },
  {
    size: "50 x50x50",
    price: 21500,
  },
  {
    size: "50x30x20",
    price: 10500,
  },
  {
    size: "50x30x30",
    price: 12700,
  },
  {
    size: "50x40x30",
    price: 15500,
  },
  {
    size: "50x42x64 (size 53)",
    price: 21500,
  },
  {
    size: "52x36x12 (size 52)",
    price: 8600,
  },
  {
    size: "60x40x20",
    price: 14800,
  },
  {
    size: "60x40x25",
    price: 15500,
  },
  {
    size: "60x40x30",
    price: 18500,
  },
  {
    size: "60x40x40 (size 54)",
    price: 19500,
  },
  {
    size: "60x50x40",
    price: 23500,
  },
  {
    size: "60x50x50",
    price: 25900,
  },
  {
    size: "8x8x4 (size 0)",
    price: 449,
  },
];

const HN_Nap_Doi_5LBC_1nau = [
  {
    size: "5 lớp 40x30x20 (size 59)",
    price: 14530,
  },
  {
    size: "5 lớp 40x30x30 (size 57)",
    price: 17290,
  },
  {
    size: "5 lớp 50x40x30",
    price: 20500,
  },
  {
    size: "5 lớp 60x40x40 (size 58)",
    price: 31850,
  },
  {
    size: "5 lớp 60x50x40",
    price: 38000,
  },
  {
    size: "5 lớp 60x50x50",
    price: 39500,
  },
];

const HN_Nap_Doi_Vien_Ke_BE_TONG_1N1M_3LC = [
  {
    size: "(H6) Hộp viên kê bê tông 32x22x22",
    price: 6517,
  },
  {
    size: "(H7) Hộp viên kê bê tông 34x24x18",
    price: 6173,
  },
  {
    size: "(V2) Hộp viên kê bê tông 25x22x18",
    price: 4957,
  },
  {
    size: "(V3) Hộp viên kê bê tông 25x22x20",
    price: 5629,
  },
];

const HN_Nap_Gai_Nau_Moc_3LE = [
  {
    size: "10x6x4cm",
    price: 990,
  },
  {
    size: "12x8x4cm",
    price: 1450,
  },
  {
    size: "15x10x4cm",
    price: 1630,
  },
  {
    size: "15x10x5cm",
    price: 1750,
  },
  {
    size: "15x5x5cm",
    price: 1590,
  },
  {
    size: "18x12x4cm",
    price: 1830,
  },
  {
    size: "20x15x5cm",
    price: 2550,
  },
  {
    size: "20x17x7cm",
    price: 3300,
  },
  {
    size: "20x20x10cm",
    price: 4405,
  },
  {
    size: "20x20x5cm",
    price: 3020,
  },
  {
    size: "21x13x5cm",
    price: 2450,
  },
  {
    size: "22x19x7cm",
    price: 3830,
  },
  {
    size: "25x17x7cm",
    price: 3790,
  },
  {
    size: "25x20x10cm",
    price: 5520,
  },
  {
    size: "25x20x5cm",
    price: 3100,
  },
  {
    size: "30x20x15cm",
    price: 8300,
  },
  {
    size: "30x20x6cm",
    price: 3990,
  },
  {
    size: "30x20x8cm",
    price: 4250,
  },
  {
    size: "30x21x7,5cm",
    price: 4550,
  },
  {
    size: "30x22x10cm",
    price: 6790,
  },
  {
    size: "30x25x6cm",
    price: 4600,
  },
  {
    size: "32x20x12cm",
    price: 6300,
  },
  {
    size: "35x25x10cm",
    price: 7200,
  },
  {
    size: "35x25x5cm",
    price: 4600,
  },
  {
    size: "35x30x8cm",
    price: 7100,
  },
];

const HN_Nap_Gai_Trang_Nau_3LE = [
  {
    size: "15x10x5cm",
    price: 1990,
  },
  {
    size: "18x12x4cm",
    price: 2100,
  },
  {
    size: "20x10x5cm",
    price: 2360,
  },
  {
    size: "20x15x5cm",
    price: 2850,
  },
  {
    size: "20x20x10cm",
    price: 5960,
  },
  {
    size: "20x20x5",
    price: "",
  },
  {
    size: "21x13x5cm",
    price: 2660,
  },
  {
    size: "25x20x10cm",
    price: 6450,
  },
  {
    size: "25x20x5cm",
    price: 3750,
  },
  {
    size: "30x20x15cm",
    price: 10900,
  },
  {
    size: "30x20x6cm",
    price: 4800,
  },
  {
    size: "30x20x8cm",
    price: 5800,
  },
  {
    size: "30x21x7,5cm",
    price: 5700,
  },
  {
    size: "30x25x6cm",
    price: 5550,
  },
  {
    size: "35x25x7cm",
    price: 6560,
  },
];

const SG_Nap_Doi_Nau_Moc_3LB = [
  {
    size: "Hộp carton 10x6x6cm (size 1)",
    price: 650,
  },
  {
    size: "Hộp carton 10x7x7cm (size 2)",
    price: 850,
  },
  {
    size: "Hộp Carton 10x10x5cm (size 3)",
    price: 900,
  },
  {
    size: "Hộp Carton 10x10x8cm (size 4)",
    price: 930,
  },
  {
    size: "Hộp Carton 10x10x10cm (size 5)",
    price: 1050,
  },
  {
    size: "Hộp Carton 12x8x8cm (size 6)",
    price: 999,
  },
  {
    size: "Hộp Carton 12x8x12cm (size 7)",
    price: 1150,
  },
  {
    size: "Hộp Carton 12x10x5cm (size 8)",
    price: 999,
  },
  {
    size: "Hộp Carton 12x12x12cm (size 9)",
    price: 1350,
  },
  {
    size: "Hộp Carton 15x8x6cm (size 10)",
    price: 900,
  },
  {
    size: "Hộp Carton 15x10x5cm (size 11)",
    price: 1050,
  },
  {
    size: "Hộp Carton 15x10x10cm (size 12)",
    price: 1200,
  },
  {
    size: "Hộp Carton 15x12x10cm (size 14)",
    price: 1300,
  },
  {
    size: "Hộp Carton 15x12x12cm",
    price: 1650,
  },
  {
    size: "Hộp Carton 15x15x10cm",
    price: 1800,
  },
  {
    size: "Hộp Carton 15x15x15cm",
    price: 2100,
  },
  {
    size: "Hộp Carton 16x6x6cm (size 15)",
    price: 900,
  },
  {
    size: "Hộp Carton 16x12x6cm (size 16)",
    price: 1200,
  },
  {
    size: "Hộp Carton 16x16x6cm",
    price: 1850,
  },
  {
    size: "Hộp Carton 18x10x8cm (size 17)",
    price: 1200,
  },
  {
    size: "Hộp Carton 18x12x8cm",
    price: 1450,
  },
  {
    size: "Hộp Carton 18x12x12cm (size 18)",
    price: 1700,
  },
  {
    size: "Hộp Carton 18x14x12cm",
    price: 1790,
  },
  {
    size: "Hộp Carton 20x8x8cm (size 19)",
    price: 1200,
  },
  {
    size: "Hộp Carton 20x10x6cm (size 20)",
    price: 1200,
  },
  {
    size: "Hộp Carton 20x18x12cm",
    price: 2490,
  },
  {
    size: "Hộp Carton 20x10x10cm (size 21)",
    price: 1400,
  },
  {
    size: "Hộp Carton 20x12x8cm (size 22)",
    price: 1500,
  },
  {
    size: "Hộp Carton 20x15x10cm (size 23)",
    price: 1890,
  },
  {
    size: "Hộp Carton 20x15x15cm (size 24)",
    price: 2250,
  },
  {
    size: "Hộp Carton 20x16x6cm (size 25)",
    price: 2100,
  },
  {
    size: "Hộp Carton 20x20x10cm (size 26)",
    price: 2480,
  },
  {
    size: "Hộp Carton 20x20x15cm (size 27)",
    price: 2800,
  },
  {
    size: "Hộp Carton 20x20x20cm (size 27)",
    price: 3300,
  },
  {
    size: "Hộp Carton 22x19x7cm",
    price: 2480,
  },
  {
    size: "Hộp Carton 22x19x14cm",
    price: 3100,
  },
  {
    size: "Hộp Carton 25x10x5 cm (size 51)",
    price: 1340,
  },
  {
    size: "Hộp carton 25x10x10cm ( size 28)",
    price: 1600,
  },
  {
    size: "Hộp Carton 25x15x10cm (size 29)",
    price: 2200,
  },
  {
    size: "Hộp Carton 25x15x15cm (size 30)",
    price: 2500,
  },
  {
    size: "Hộp Carton 25x17x7cm",
    price: 2500,
  },
  {
    size: "Hộp Carton 25x20x10cm (size 31)",
    price: 2750,
  },
  {
    size: "Hộp Carton 25x20x15cm (size 32)",
    price: 3550,
  },
  {
    size: "Hộp Carton 25x22x12cm (size 55)",
    price: 3250,
  },
  {
    size: "Hộp Carton 25x22x15cm",
    price: 3700,
  },
  {
    size: "Hộp Carton 25x22x20cm (size 33)",
    price: 3900,
  },
  {
    size: "Hộp Carton 25x25x10cm",
    price: 3760,
  },
  {
    size: "Hộp Carton 25x25x15cm",
    price: 4050,
  },
  {
    size: "Hộp Carton 25x25x25cm",
    price: 4800,
  },
  {
    size: "Hộp Carton 28x11x8cm",
    price: 1900,
  },
  {
    size: "Hộp Carton 28x17x7cm",
    price: 2600,
  },
  {
    size: "Hộp Carton 28x18x12cm",
    price: 2900,
  },
  {
    size: "Hộp Carton 28x20x8cm",
    price: 2850,
  },
  {
    size: "Hộp Carton 28x23x16cm (size 34)",
    price: 4290,
  },
  {
    size: "Hộp Carton 28x24x11cm (size 35)",
    price: 4200,
  },
  {
    size: "Hộp Carton 30x10x10cm (size 36)",
    price: 1800,
  },
  {
    size: "Hộp Carton 30x15x10cm (size 37)",
    price: 2400,
  },
  {
    size: "Hộp Carton 30x15x15cm (size 38)",
    price: 2900,
  },
  {
    size: "Hộp Carton 30x20x10cm (size 39)",
    price: 3050,
  },
  {
    size: "Hộp Carton 30x20x15cm (size 40)",
    price: 3490,
  },
  {
    size: "Hộp Carton 30x20x20cm",
    price: 4100,
  },
  {
    size: "Hộp Carton 30x21x7cm",
    price: 3150,
  },
  {
    size: "Hộp Carton 30x25x20cm (size 41)",
    price: 4950,
  },
  {
    size: "Hộp Carton 30x25x25cm (size 42)",
    price: 5750,
  },
  {
    size: "Hộp Carton 30x30x10cm",
    price: 4900,
  },
  {
    size: "Hộp Carton 30x30x20cm",
    price: 6500,
  },
  {
    size: "Hộp Carton 30x30x30cm",
    price: 7400,
  },
  {
    size: "Hộp Carton 33x22x12cm (size 43)",
    price: 4200,
  },
  {
    size: "Hộp Carton 34x24x18cm (size 44)",
    price: 5050,
  },
  {
    size: "Hộp Carton 30x25x15cm",
    price: 4350,
  },
  {
    size: "Hộp Carton 35x25x15cm",
    price: 5345,
  },
  {
    size: "Hộp Carton 35x25x20cm (size 56)",
    price: 5500,
  },
  {
    size: "Hộp Carton 40x20x20cm",
    price: 5500,
  },
  {
    size: "Hộp Carton 40x30x20cm (size 49)",
    price: 7500,
  },
  {
    size: "Hộp Carton 40x30x30cm (size 50)",
    price: 10500,
  },
  {
    size: "Hộp Carton 40x40x30cm (size 50)",
    price: 11200,
  },
  {
    size: "Hộp Carton 40x40x40cm (size 50)",
    price: 12990,
  },
  {
    size: "Hộp Carton 50x30x30cm",
    price: 11900,
  },
  {
    size: "Hộp Carton 50x40x30cm",
    price: 14500,
  },
  {
    size: "Hộp Carton 60x40x20cm",
    price: 14800,
  },
  {
    size: "Hộp Carton 60x40x30cm",
    price: 16000,
  },
  {
    size: "Hộp Carton 60x40x40cm",
    price: 18000,
  },
  {
    size: "Hộp Carton 60x50x50cm",
    price: 24900,
  },
];

const SG_Nap_Doi_Nau_Moc_5LB = [
  {
    size: "Hộp Carton 5 lớp 40x30x20cm (size 59)",
    price: 13900,
  },
  {
    size: "Hộp Carton 5 lớp 40x30x30cm (size 57)",
    price: 16500,
  },
  {
    size: "Hộp Carton 5 lớp 50x40x30cm",
    price: 19000,
  },
  {
    size: "Hộp Carton 5 lớp 60x40x40cm (size 58)",
    price: 29000,
  },
  {
    size: "Hộp Carton 5 lớp 60x50x40cm",
    price: 37000,
  },
  {
    size: "Hộp Carton 5 lớp 60x50x50cm",
    price: 38000,
  },
];

const SG_Nap_Gai_Nau_Moc_3LE = [
  {
    size: "10x6x4cm",
    price: 990,
  },
  {
    size: "12x8x4cm",
    price: 1450,
  },
  {
    size: "15x10x5cm",
    price: 1750,
  },
  {
    size: "15x5x5cm",
    price: 1590,
  },
  {
    size: "18x12x4cm",
    price: 1830,
  },
  {
    size: "20x10x5cm",
    price: 2290,
  },
  {
    size: "20x15x5cm",
    price: 2550,
  },
  {
    size: "20x17x7cm",
    price: 3300,
  },
  {
    size: "20x20x10cm",
    price: 4405,
  },
  {
    size: "20x20x5cm",
    price: 3020,
  },
  {
    size: "21x13x5cm",
    price: 2450,
  },
  {
    size: "22x19x7cm",
    price: 3830,
  },
  {
    size: "25x17x7cm",
    price: 3790,
  },
  {
    size: "25x20x10cm",
    price: 5520,
  },
  {
    size: "25x20x5cm",
    price: 3100,
  },
  {
    size: "30x20x15cm",
    price: 8300,
  },
  {
    size: "30x20x6cm",
    price: 3990,
  },
  {
    size: "30x20x8cm",
    price: 4250,
  },
  {
    size: "30x21x7,5cm",
    price: 4550,
  },
  {
    size: "30x22x10cm",
    price: 6790,
  },
  {
    size: "30x25x6cm",
    price: 4600,
  },
  {
    size: "32x20x12cm",
    price: 6300,
  },
  {
    size: "35x25x10cm",
    price: 7200,
  },
  {
    size: "35x25x13 có in 2 nâu",
    price: 10036,
  },
  {
    size: "35x25x5cm",
    price: 4600,
  },
  {
    size: "35x30x8cm",
    price: 7100,
  },
];

const SG_Nap_Gai_Trang_Nau_3LE = [
  {
    size: "15x10x5cm",
    price: 1990,
  },
  {
    size: "18x12x4cm",
    price: 2100,
  },
  {
    size: "20x10x5cm",
    price: 2360,
  },
  {
    size: "20x15x5cm",
    price: 2850,
  },
  {
    size: "20x20x10cm",
    price: 5960,
  },
  {
    size: "21x13x5cm",
    price: 2660,
  },
  {
    size: "25x20x10cm",
    price: 6450,
  },
  {
    size: "25x20x5cm",
    price: 3750,
  },
  {
    size: "30x20x15cm",
    price: 10900,
  },
  {
    size: "30x20x6cm",
    price: 4800,
  },
  {
    size: "30x20x8cm",
    price: 5800,
  },
  {
    size: "30x21x7,5cm",
    price: 5700,
  },
  {
    size: "30x25x6cm",
    price: 5550,
  },
  {
    size: "35x25x7cm",
    price: 6560,
  },
];

export {
  HN_Nap_Doi_5LBC_1nau,
  HN_Nap_Doi_Nau_Moc_3LB,
  HN_Nap_Doi_Vien_Ke_BE_TONG_1N1M_3LC,
  HN_Nap_Gai_Nau_Moc_3LE,
  HN_Nap_Gai_Trang_Nau_3LE,
  SG_Nap_Doi_Nau_Moc_3LB,
  SG_Nap_Doi_Nau_Moc_5LB,
  SG_Nap_Gai_Nau_Moc_3LE,
  SG_Nap_Gai_Trang_Nau_3LE,
};
