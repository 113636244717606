import React, { useState } from "react";
import { Tabs } from "antd";
import { CPNTable } from "../reComponent/table";
import {
  HN_TuiGiay_Ivory,
  HN_TuiGiay_Kraft_120,
  HN_TuiGiay_Kraft_Nhieu_Mau,
  HN_TuiGiay_Kraft_Thankyou,
} from "../dataTable/tuigiay";

const TuiGiay = () => {
  const [tabPosition, setTabPosition] = useState("top");
  const columnsTuiGiay = [
    {
      title: "Kích thước",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Giá bán",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Màu",
      dataIndex: "color",
      key: "color",
    },
  ];
  const dataTab = [
    {
      key: `1`,
      label: `Túi Giấy Kraft 120`,
      children: CPNTable(columnsTuiGiay, HN_TuiGiay_Kraft_120),
    },
    {
      key: `2`,
      label: `Túi Giấy Kraft Nhiều Màu`,
      children: CPNTable(columnsTuiGiay, HN_TuiGiay_Kraft_Nhieu_Mau),
    },
    {
      key: `3`,
      label: `Túi Giấy Ivory`,
      children: CPNTable(columnsTuiGiay, HN_TuiGiay_Ivory),
    },
    {
      key: `5`,
      label: `Túi Giấy Kraft Thankyou`,
      children: CPNTable(columnsTuiGiay, HN_TuiGiay_Kraft_Thankyou),
    },
  ];
  return (
    <>
      <Tabs tabPosition={tabPosition} items={dataTab} />
    </>
  );
};
export default TuiGiay;
