const HN_TuiGiay_Kraft_120 = [
  {
    code: "KRAFT 120",
    size: "15,5 x 9 x21",
    price: 2600,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "15,5 x 9 x28",
    price: 2800,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "19,5 x 10 x33",
    price: 3100,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "20x 15 x21,5",
    price: 2950,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "15,5x8x22",
    price: 3150,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "18 x12 x24",
    price: 3100,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "13,5 x8 x33",
    price: 3130,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "13,5 x8 x39",
    price: 3360,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "26x17,5 x 30,5",
    price: 4100,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "25 x 14,5 x30",
    price: 3950,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "18x12x39",
    price: 4450,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "25x14x30",
    price: 3900,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "25x17x31",
    price: 4040,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "33x17,5x43",
    price: 6450,
    color: "Nâu",
  },
  {
    code: "KRAFT 120",
    size: "15,5x 9 x28",
    price: 3300,
    color: "Trắng",
  },
  {
    code: "KRAFT 120",
    size: "15,5x8x22",
    price: 3150,
    color: "Trắng",
  },
  {
    code: "KRAFT 120",
    size: "13,5x 8 x22",
    price: 2800,
    color: "Trắng",
  },
  {
    code: "KRAFT 120",
    size: "26x17x29",
    price: 3400,
    color: "Trắng",
  },
  {
    code: "KRAFT 120",
    size: "13,5 x8 x33",
    price: 3250,
    color: "Trắng",
  },
  {
    code: "KRAFT 120",
    size: "33x17,5x43",
    price: 6950,
    color: "Trắng",
  },
  {
    code: "KRAFT 120",
    size: "25x 11 x31",
    price: 5260,
    color: "Đen",
  },
  {
    code: "KRAFT 120",
    size: "32x14x42",
    price: 6800,
    color: "Đen",
  },
  {
    code: "KRAFT 120",
    size: "25x 11 x31",
    price: 5360,
    color: "Hồng",
  },
  {
    code: "KRAFT 120",
    size: "32x14x42",
    price: 6900,
    color: "Hồng",
  },
];

const HN_TuiGiay_Kraft_Nhieu_Mau = [
  {
    code: "Kraft Nhiều Màu",
    size: "12x6x22",
    price: 2600,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam, Đỏ",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "19,5 x8 x20,5",
    price: 2900,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam , Đỏ",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "15,5 x 9 x25",
    price: 3080,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "19,5x10x30",
    price: 4300,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "30x12x25",
    price: 4200,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam , Đỏ",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "35x13x25",
    price: 5100,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "30x13x30",
    price: 5050,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "30x15x40",
    price: 5580,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam",
  },
  {
    code: "Kraft Nhiều Màu",
    size: "35x13x29,5",
    price: 5750,
    color: "Nâu, Trắng, Xanh, Hồng, Vàng, Cam , Đỏ",
  },
];

const HN_TuiGiay_Kraft_Thankyou = [
  {
    code: "KraftThankyou",
    size: "21,5x11,5 x27",
    price: 5100,
    color: "Đen Thankyou",
  },
  {
    code: "KraftThankyou",
    size: "32x10x24",
    price: 4950,
    color: "Xanh Thankyou",
  },
];
const HN_TuiGiay_Ivory = [
  {
    code: "Túi Ivory",
    size: "36x13x26",
    price: 7040,
    color: "Trắng cán bóng",
  },
  {
    code: "Túi Ivory",
    size: "22x9x18",
    price: 4500,
    color: "Trắng cán bóng",
  },
  {
    code: "Túi Ivory",
    size: "18x8x14",
    price: 3600,
    color: "Trắng cán bóng",
  },
  {
    code: "Túi Ivory",
    size: "15x5x10",
    price: 3300,
    color: "Trắng cán bóng",
  },
  {
    code: "Túi Ivory",
    size: "16x6x12",
    price: 3350,
    color: "Trắng cán bóng",
  },
  {
    code: "Túi Ivory",
    size: "18x10x22",
    price: 4500,
    color: "Trắng cán bóng",
  },
];

export {
    HN_TuiGiay_Kraft_120,
    HN_TuiGiay_Kraft_Nhieu_Mau,
    HN_TuiGiay_Kraft_Thankyou,
    HN_TuiGiay_Ivory
  };